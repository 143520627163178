import React from 'react';
import Layout from '../../../layouts/index';

import SuccessBrand from '../../../components/order/PaymentSuccessBrand';

const PaymentSuccessContainerBrand = () => (
  <Layout>
    <SuccessBrand />
  </Layout>
);

export default PaymentSuccessContainerBrand;
